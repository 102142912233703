import React from "react";

import WebDevelopmentImage from "../assets/services/WebDevelopment.png";
import AndroidAppDevelopmentImage from "../assets/services/AndroidAppDevelopment.png";
import AttendanceMachineIntegrationImage from "../assets/services/AttendanceMachineIntegration.png";
import DesktopDevelomentImage from "../assets/services/DesktopDeveloment.png";
import GraphicDesignImage from "../assets/services/GraphicDesign.png";
import HospitalMachineIntegrationImage from "../assets/services/HospitalMachineIntegration.png";
import UIUXImage from "../assets/services/UI-UX.png";
import ProductDesignImage from "../assets/services/ProductDesign.png";

function OurServices() {
  return (
    <section className="mt-[185px] mb-[115px] container px-2.5 md:px-0 text-center">
      <h3 className="text-[40px] text-black font-medium mb-[97px]">
        Our Services
      </h3>

      <div className="grid grid-cols-3 md:grid-cols-6 xl:grid-cols-12 gap-16">
        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={WebDevelopmentImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Web Development
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={UIUXImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            UI/UX Design
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={DesktopDevelomentImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Desktop Development
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={GraphicDesignImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Graphic Design
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={ProductDesignImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Product Design
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={AndroidAppDevelopmentImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Android App Development
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={AttendanceMachineIntegrationImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Attendance Machine Integration
          </p>
        </div>

        <div className="col-span-3 border-2 rounded-lg pt-[90px] pb-5 px-5 flex flex-col justify-center items-center">
          <img src={HospitalMachineIntegrationImage} />
          <p className="my-[88px] mb-[44px] text-2xl text-purple font-medium leading-normal">
            Hospital Machine Integration
          </p>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
