import HeroSection from "./components/Hero";
import OurServices from "./components/OurServices";
import ClientTestimonialSection from "./components/ClientTestimonialSection";
import CoverageMapSection from "./components/CoverageMapSection";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <HeroSection />

      <OurServices />

      <ClientTestimonialSection />

      <CoverageMapSection />

      <Footer />
    </>
  );
}

export default App;
