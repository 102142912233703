import UserOne from "../assets/users/UserOne.png";
import UserSecond from "../assets/users/UserSecond.png";

import JapanFlag from "../assets/flag/jp.png";
import ItalyFlag from "../assets/flag/it.png";
import USAFlag from "../assets/flag/us.png";

function ClientTestimonialSection() {
  return (
    <section className="bg-purple pb-[214px]">
      <div className="container px-2.5 md:px-0 text-center">
        <h3 className="text-[40px] font-medium leading-normal text-white pt-[140px] pb-[210px] ">
          What our customers say about working
        </h3>

        <div className="grid grid-cols-4 xl:grid-cols-12 gap-24 justify-between">
          <div className="col-span-4 py-10 px-5 xl:py-14 xl:px-8 border-1 rounded-md bg-white ">
            <div className="flex flex-col justify-center items-center">
              <img src={UserOne} />

              <p className="mt-5 mb-2.5 text-lg font-medium">Magesh_jp</p>
              <p className="flex justify-center items-center text-base">
                <img src={JapanFlag} className="mr-3" /> Japan
              </p>
            </div>

            <p className="mt-10 text-xl xl:text-[22px] leading-normal">
              Meet our expectations. Had few revision done without any hesitate.
              Had meeting whenever have difference and its went smooth
            </p>
          </div>

          <div className="col-span-4 py-10 px-5 xl:py-14 xl:px-8 border-1 rounded-md bg-white scale-1 md:scale-[1.16]">
            <div className="flex flex-col justify-center items-center">
              <img src={UserSecond} />

              <p className="mt-5 mb-2.5 text-lg font-medium">Magesh_jp</p>
              <p className="flex justify-center items-center text-base">
                <img src={USAFlag} className="mr-3" /> United State
              </p>
            </div>

            <p className="mt-10 text-xl xl:text-[22px] leading-normal">
              3rd time working with Giga key sol and he absolutely nails it..
              open to modifications, swift, creative and clean design acumen..
              communication on point but most of all, treats his work like
              worship.. id recommend it without any doubt whatsoever.. keep
              going!
            </p>
          </div>

          <div className="col-span-4 py-10 px-5 xl:py-14 xl:px-8 border-1 rounded-md bg-white ">
            <div className="flex flex-col justify-center items-center">
              <img src={UserOne} />

              <p className="mt-5 mb-2.5 text-lg font-medium">Magesh_jp</p>
              <p className="flex justify-center items-center text-base">
                <img src={ItalyFlag} className="mr-3" /> Italy
              </p>
            </div>

            <p className="mt-10 text-xl xl:text-[22px] leading-normal">
              He has responded very quickly to our request, both to start the
              project and when requesting changes. He has managed to capture the
              objectives we were looking for.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientTestimonialSection;
