function Footer() {
  return (
    <section className="bg-black">
      <div className="container px-2.5 md:px-0 text-center py-5">
        <a
          href="mailto:gigakeys@gamil.com"
          className="text-white text-[22px] font-normal leading-normal"
        >
          gigakeys@gamil.com
        </a>
      </div>
    </section>
  );
}

export default Footer;
