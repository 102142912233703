import CoverageMapImage from "../assets/map/Group 2274.png";

function CoverageMapSection() {
  return (
    <section className="bg-purple pb-[127px]">
      <div className="container px-2.5 md:px-0 text-center">
        <h3 className="pb-[80px] text-white text-[40px] font-medium leading-normal">
          Worldwide Work Area
        </h3>
        <img src={CoverageMapImage} className="mx-auto" />
      </div>
    </section>
  );
}

export default CoverageMapSection;
