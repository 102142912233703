import Logo from "../assets/GigaKeysSolutionsLogo.png";
import HeroImage from "../assets/HeroImage.png";

import CardCryptoLogo from "../assets/brands/CardCrypto.png";
import CarFixxLogo from "../assets/brands/CarFixx.png";
import DailySafeLogo from "../assets/brands/DailySafe.png";
import FocalSoftwareLogo from "../assets/brands/FocalSoftware.png";
import KRLogo from "../assets/brands/KR.png";
import SmabooLogo from "../assets/brands/Smaboo.png";
import UGottaEatLogo from "../assets/brands/UGottaEat.png";
import VIPBallersLogo from "../assets/brands/VIPBallers.png";

import { BsEnvelope, BsWhatsapp } from "react-icons/bs";

function HeroSection() {
  return (
    <div className="bg-purple">
      <header className="container px-2.5 md:px-0 pt-[21px] pb-4 text-white flex justify-end items-center">
        <div className="flex items-center">
          <BsWhatsapp className="mr-2.5 w-5 h-5 xl:w-6 xl:h-6 2xl:h-8 2xl:w-8" />
          <a
            href="https://wa.me/92xxxxxxxxxx"
            target="_blank"
            className="text-sm 2xl:text-base"
          >
            +92 XXX XXXXXXX
          </a>
        </div>

        <div className="text-[28px] mx-5 xl:mx-7 2xl:mx-9">|</div>

        <div className="flex items-center">
          <BsEnvelope className="mr-2.5 w-5 h-5 xl:w-6 xl:h-6 2xl:h-8 2xl:w-8" />
          <a href="mailto:gigakeys@gamil.com" className="text-sm 2xl:text-base">
            gigakeys@gamil.com
          </a>
        </div>
      </header>

      <hr className="border-t-[1px] border-white/30" />

      <div className="heroSection container px-2.5 md:px-0 relative">
        <section className="mt-5 container">
          <div className="flex justify-between mb-[100px]">
            <img
              src={Logo}
              alt="Giga Keys Solutions"
              className="inline-block w-44 2xl:w-52 3xl:w-60"
            />

            <div className="flex flex-col">
              <a
                href="#"
                className="px-6 py-2 3xl:px-9 3xl:py-3 text-golden border-2 border-golden rounded-sm"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>

        <section className="container px-2.5 md:px-0 grid grid-cols-6 md:grid-cols-12 gap-16 mb-[150px]">
          <div className="col-span-6">
            <h1 className="text-[50px] xl:text-[60px] 2xl:text-[70px] 3xl:text-[87px] text-white font-medium leading-[1.3] text-center md:text-left">
              Our{" "}
              <span className="text-golden text-[60px] xl:text-[70px] 2xl:text-[80px] 3xl:text-[100px]">
                Serv<span className="text-white">i</span>
                ces
              </span>{" "}
              Are Intelligent
            </h1>

            <div className="mt-[75px] flex justify-around md:grid grid-cols-12 gap-4 ">
              <div className="col-span-5 xl:col-span-3">
                <h3 className="text-5xl text-golden font-bold">340+</h3>
                <p className="text-white font-light mt-2.5">Projects</p>
              </div>

              <div className="col-span-5 xl:col-span-3">
                <h3 className="text-5xl text-golden font-bold">90+</h3>
                <p className="text-white font-light mt-2.5">Global Clients</p>
              </div>
            </div>
          </div>

          <div className="col-span-6 flex justify-end">
            <img src={HeroImage} className="w-full h-auto object-contain" />
          </div>
        </section>

        <section className="container px-2.5 md:px-0">
          <div className="flex justify-center">
            <h3 className="text-[24px] xl:text-[32px] text-white font-medium mb-[130px]">
              Companies we've proudly helped build
            </h3>
          </div>
        </section>

        <div className="brandsLogoSection absolute bottom-[-86px] bg-white py-8 px-8 w-full drop-shadow-lg rounded-lg">
          <div className="grid xl:flex grid-cols-8 gap-4 justify-between items-center">
            <img src={CardCryptoLogo} className="object-contain" />
            <img src={CarFixxLogo} className="object-contain" />
            <img src={DailySafeLogo} className="object-contain" />
            <img src={FocalSoftwareLogo} className="object-contain" />
            <img src={KRLogo} className="object-contain" />
            <img src={SmabooLogo} className="object-contain" />
            <img src={UGottaEatLogo} className="object-contain" />
            <img src={VIPBallersLogo} className="object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
